export default function OpenDropLogo() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="54.695"
        height="39.881"
        version="1.1"
        viewBox="0 0 14.471 10.552"
      >
        <g transform="translate(-75.824 -113.02)">
          <g
            stroke="#000"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            transform="translate(19.732 57.12)"
          >
            <path
              fill="#fff"
              fillOpacity="1"
              strokeWidth="0.232"
              d="M62.877 58.653c-.396-.58-.33-1.51-.23-1.662.099-.152.922-.306 1.6-.114.678.193 1.14.719 1.14.719s-.386.277-.497.669c-.111.391.008.973.008.973-.687.194-1.69-.148-2.02-.585z"
            ></path>
            <path
              fill="#fff"
              fillOpacity="1"
              strokeWidth="0.232"
              d="M65.4 57.573c-.338-.427-.24-1.186-.174-1.286.087-.133.419-.03.741.076.33.108.576.383.634.55-.208.172-.445.313-.578.543-.277-.064-.391-.03-.622.117z"
            ></path>
            <path
              fill="#fff"
              fillOpacity="1"
              strokeWidth="0.232"
              d="M66.633 56.884c.32-.212.643-.276.95-.314-.136-.252-.512-.496-.71-.55-.332.1-.323.521-.24.864z"
            ></path>
            <path
              fill="#fff"
              fillOpacity="1"
              strokeWidth="0.232"
              d="M66.055 57.465a.77.77 0 00-.033.716c.21.345 1.102.91 1.385.817.211-.177.561-.917.561-1.285 0-.368-.26-1.14-.435-1.133-.175.005-1.029.213-1.478.885z"
            ></path>
            <path
              fill="#fff"
              fillOpacity="1"
              strokeWidth="0.232"
              d="M67.95 57.268c.297.08.76-.4.81-.558.05-.157.78.665.748 1.02-.033.356-.194.653-.579.732-.372.076-1.036.078-1.375.363.38-.582.447-1.063.396-1.557z"
            ></path>
            <path
              fill="#fff"
              fillOpacity="1"
              strokeWidth="0.232"
              d="M67.185 59.284c.234-.35.777-.736 1.268-.742.543-.005 1.11-.274 1.647-.975.19-.248.596.946.135 2.114a2.67 2.67 0 01-1.099 1.262c-.496 0-.998-.274-1.273-.864-.275-.59-.678-.795-.678-.795z"
            ></path>
            <path
              fill="#fff"
              fillOpacity="1"
              strokeWidth="0.232"
              d="M67.156 59.29c-.923-.304-1.77-.152-2.202-.064-.108-.294-.126-1.089.146-1.361.432-.433.923-.573.888-.322-.035.252-.108.6.298.94.337.285.69.416 1.104.556-.146.11-.234.251-.234.251z"
            ></path>
            <path
              fill="#5adf3f"
              fillOpacity="1"
              strokeWidth="0.348"
              d="M61.373 58.794c.55-.293 1.087-.549 1.694.07.63.642 1.88.438 2.337.362.56-.094 1.868-.133 2.196.514.508 1.002 1.752 1.62 2.3 1.41.55-.21-.513 1.523-1.167 1.874.151.95-.769 2.566-1.462 2.695-.619.094-1.774-.301-2.37-.558-.596-.257-.643.056-1.028.15-.386.093-.77-.279-.981-.302-.21-.023-1.204 1.373-2.045 1.262-1.42-.188-2.418-2.874-3.014-3.178-.596-.304-1.221-.506-1.54-.823.151-.432.466-.932.735-1.152.608.012 1.745.557 1.932.242.187-.316-.241-.179-.227-.534.025-.664 2.64-2.032 2.64-2.032z"
            ></path>
            <path
              fill="none"
              strokeWidth="0.348"
              d="M60.201 59.465c1.164-.58 2.545-.111 2.907.875.323.88.44 1.5.44 1.5"
            ></path>
            <path
              fill="#000"
              fillOpacity="1"
              strokeWidth="0.348"
              d="M69.133 61.078c-1.168-.444-1.799-.462-2.377-.131-.578.33-1.799 1.127-1.799 1.127s2.313-1.636 2.512-2.033c.198-.396.928.796.928.796z"
            ></path>
            <path
              fill="#000"
              fillOpacity="1"
              strokeWidth="0.348"
              d="M57.961 63.017c.736-.269.994-.532.994-.532s.467.433 1.203.48c.736.046 1.118.017.83.198-.439.105-2.269-.019-2.623.1-.345-.13-.404-.246-.404-.246z"
            ></path>
            <path
              fill="#c1dcfc"
              fillOpacity="1"
              strokeWidth="0.232"
              d="M64.687 63.026c-.454.38-2.189.43-2.429.107-.24-.322.044-.82.347-1.024.31-.208.992-.454 1.562-.157.57.297.975.694.52 1.074z"
            ></path>
            <path
              fill="#000"
              fillOpacity="1"
              strokeWidth="0.232"
              d="M64.888 62.892c.446-.248 1.022-1.114 1.03-1.304.009-.19-.305.15-.487.025-.182-.124-.132-.578-.339-.694-.206-.115-1.111.554-1.293.876-.289-.041-.888.045-1.185.306-.397-.133-.942-.438-1-.34-.058.1-.14 1.065.223 1.265.165.09.19.095.347.083-.136-.347.132-.798.318-.934.228-.167.756-.421 1.045-.405.29.017 1.05.295 1.224.527.173.231.117.595.117.595z"
            ></path>
            <path
              fill="#000"
              fillOpacity="1"
              strokeWidth="0.232"
              d="M63.854 62.19c-.227-.063-.792.019-.799.087-.021.232 1.112-.106 1.01.633.146-.037.523-.135.425-.32-.038-.071-.409-.336-.636-.4z"
            ></path>
          </g>
        </g>
      </svg>
    );
  }